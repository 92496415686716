body,
html {
	height: 100%
}

body {
	font-size: 18px;
	transition: all .3s ease;
	font-family: Montserrat, sans-serif;
	padding: 0 50px
}

a {
	color: #bc4124
}

a:hover {
	color: #ad3b1f
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Open Sans', sans-serif
}

.input-group-button input {
	min-width: 150px
}

.langs {
	display: flex;
	flex-direction: row;
	margin: 0;
	padding: 0;
	list-style: none
}

.langs__item {
	position: relative;
	font-size: 14px
}

.langs__item:after {
	content: '|';
	position: absolute;
	right: 6;
	top: 0
}

.theme-dark {
	background: #0b172a;
	color: #fff
}

.theme-dark-qrcode {
	color: #fff
}

.app-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100%
}

.app-container>div:first-of-type {
	margin-top: 50px
}

footer {
	margin-top: auto;
	margin-bottom: 20px
}

.logo {
	max-width: 64px;
	height: auto
}

.animated-search-form[type=text] {
	width: 400px;
	border: .125rem solid #e6e6e6;
	box-shadow: 0 0 3.125rem rgba(0, 0, 0, .18);
	border-radius: 0;
	background-position: .625rem .625rem;
	background-repeat: no-repeat;
	padding: .75rem 1.25rem .75rem 1.25rem
}

.output-field-width[type=text] {
	width: 400px;
}

.label-align {
	width: 550px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.menu .is-active>a {
	background: #bc4124
}

.menu {
	margin-bottom: 40px;
}

.button,
.button.disabled,
.button.disabled:focus,
.button.disabled:hover,
.button[disabled],
.button[disabled]:focus,
.button[disabled]:hover {
	background-color: #bc4123;
}

.button:focus,
.button:hover {
	background-color: #ad3b1f;
	outline: 0;
}

.button:active {
	background-color: transparent;
	color: #bc4123;
	border: 1px solid #bc4123;
}

input:checked~.switch-paddle {
	background-color: #bc4123
}

/* Footer and scrollbar from index.html*/
.footer-keywords {
	position: relative;
	max-width: 400px;
	font-size: 12px;
	height: 90px;
	margin: 20px auto;
	/*overflow-y: auto;*/
	overflow: hidden;
	font-weight: bold;
	color: #969696;
	text-align: justify;
	transition: none;
}

.footer-keywords:before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: linear-gradient(transparent 45px, #fff);
}

.theme-dark .footer-keywords {
	color: #58606d;
}

.theme-dark .footer-keywords:before {
	background: linear-gradient(transparent 45px, #0c172a);
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* Utiles */

.container-center {
	margin: 60px 250px;
}

.w-40 {
	width: 40%;
}

.logo-link {
	color: #0a0a0a;
}

.logo-link:hover {
	color: rgb(73, 73, 73);
}

.theme-dark .logo-link {
	color: #fff
}

.theme-dark .logo-link:hover {
	color: rgb(209, 209, 209);
}

/*QR-code  NEW*/

#shareboxes {
	display: none;
}

#qrcode {
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	margin: 12px 0
}

#qrcode img {
	border: 5px solid #ffffff
}

#qrcode canvas {
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
}

#qrcodeHiRes {
	display: none;
}

.qrLinkContainer {
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	position: relative;
	width: 434px;
	height: 614px;
	left: calc(50% - 434px/2 + 0.4px);
	background: white;
	box-shadow: 2px 0px 15px rgba(158, 158, 158, 0.25);
}

.qrLink__QRCode img {
	width: 9.5em;
	height: 9.5em;
	border-radius: 0.25em;
}

.qrLink,
.clonedQrLink {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 10.75em;
	height: 10.75em;
	position: relative;
	margin: 50px auto;
	border-radius: 0.5em;
}

.qrLink__corners {
	display: flex;
	position: absolute;
	width: 11.5em;
	height: 11.5em;
}

.qrLink__white_corners {
	display: none;
	position: absolute;
	width: 11.5em;
	height: 11.5em;
}

.theme-dark-qrcode .qrLink__top,
.theme-dark-qrcode .qrLink__bottom,
.theme-dark-qrcode .qrLink__left,
.theme-dark-qrcode .label-align {
	color: white;
}

.qrLink__top {
	position: absolute;
	text-align: center;
	color: black;
	top: -7%;
	width: 70%;
	font-weight: 800;
	font-family: Montserrat;
	font-size: 1rem;
	text-transform: uppercase;
}

.qrLink__bottom {
	position: absolute;
	text-align: center;
	color: black;
	bottom: -0.9em;
	width: 70%;
	font-weight: 400;
	font-size: 0.75rem;
	font-family: Montserrat;
}

.qrLink__left {
	position: absolute;
	transform: rotate(-90deg);
	text-align: center;
	color: black;
	left: -6.9em;
	width: 70%;
	font-weight: 400;
	font-size: 0.65rem;
	font-family: 'Open Sans', sans-serif;
}

.qrLink__right {
	content: "";
	position: absolute;
	transform: rotate(90deg);
	right: -2.24em;
	width: 70%;
	height: 0.75em;
}

/*Buttons Print and Download*/

.btnPrintDownload {
	width: 150px;
	height: 39px;
	margin-top: 30px;
}

#saveQRCodeHQ {
	margin-left: 36px;
}

/*Annotation Label and Input Field*/

.annText {
	display: flex;
	width: 360px;
	color: #0a0a0a;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 25px;
}

.inputAnnotation {
	white-space: normal;
	resize: none;
	width: 360px;
	min-height: 200px;
	margin-bottom: 50px;
	font-size: 15px;
	line-height: 25px;
	font-weight: normal;
}

/*Screen size*/

@media screen and (min-width:510px) and (max-width:620px) {

	/*.animated-search-form[type=text] {
		width: 320px;
	}*/
	.input-group {
		width: 480px;
		margin: 0 auto;
	}

	.label-align {
		width: 480px;
		margin: 0 auto;
		/*white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;*/
		color: black;
	}

	.qrLinkContainer {
		width: 480px;
		left: calc(50% - 480px/2 + 0.4px);
	}
}

@media screen and (max-width:509px) {

	/*.animated-search-form[type=text]{
		width: 180px;
	}*/
	.input-group {
		width: 330px;
		margin: 0 auto;
	}

	.label-align {
		width: 330px;
		margin: 0 auto;
	}

	.qrLinkContainer {
		width: 330px;
		left: calc(50% - 330px/2 + 0.4px);
	}

	.qrLink__QRCode {
		max-width: 85%;
		max-height: 85%;
	}

	.img {
		max-width: 100%;
		max-height: 100%;
	}

	.annText {
		width: 280px;
	}

	.inputAnnotation {
		width: 280px;
	}
}

/*Print page*/

.printHeader,
#textFromTextarea {
	display: none;
}

@media print {

	title,
	footer,
	.sticky-social-bar,
	.menu,
	.langButton,
	#form1,
	.text-center,
	.label-align,
	.input-group,
	#printQRCodeBtn,
	#saveQRCodeHQ,
	#annotationText,
	#annotation,
	.social-icons-container,
	.social-bar,
	.social-icon,
	.moveSocialIconsHere,
	.qrLink__white_corners {
		display: none;
	}

	.qrLinkContainer {
		display: flex;
		width: 100%;
		height: auto;
		position: absolute;
		top: 0;
		left: 0;
		margin-top: 10px;
	}

	.printHeader {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-family: 'Open Sans';
		width: 100%;
		height: 56px;
		margin-top: 100px;
	}

	.word {
		margin-right: 25px;
		line-height: 28px;
		font-size: 18px;
		font-weight: 700;
		font-style: normal;
		color: black;
		font-family: 'Open Sans';
		text-align: center;
		align-items: center;
	}

	#textFromTextarea {
		display: flex;
		text-align: center;
		justify-content: center;
		width: 90%;
		min-height: 50px;
		padding: 20px;
		overflow-wrap: break-word;
		word-break: break-all;
	}

	.qrLink__QRCode img {
		width: 10.5em;
		height: 10.5em;
	}

	.clonedQrLink {
		position: relative;
		width: 11.75em;
		height: 11.75em;
		margin-top: 30px;
	}

	.qrLink__corners {
		width: 12.5em;
		height: 12.5em;
	}

	.qrLink__top {
		top: -7%;
		font-size: 1.2rem;
	}

	.qrLink__bottom {
		bottom: -0.9em;
	}

	.qrLink__left {
		left: -7.4em;
	}

	.qrLink__QRCode {
		width: 10.5em;
		height: 10.5em;
	}

	.img {
		width: 10.5em;
		height: 10.5em;
	}
}


/*new*/
.social-icons-container,
.moveSocialIconsHere {
	box-sizing: border-box;
	display: block;
}

.social-bar {
	width: 550px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
	gap: 11px;
	margin: 0;
	margin-bottom: 10px;
	z-index: 9999;
}

.social-bar .social-icon {
	width: 32px;
	height: 32px;
	list-style-type: none;
	color: #fefefe;
	background-color: inherit;
	margin: 0;
	cursor: pointer;
}

.social-bar .social-icon>a {
	color: inherit;
	background-color: inherit;
}

.social-bar .social-icon>a>.fa-brands {
	padding: .45rem;
	width: 2rem;
	height: 2rem;
	border-radius: 100px;
}

.social-bar .social-icon>a>.fa-brands[class*="facebook"] {
	background-color: #3b5998;
}

.social-bar .social-icon>a>.fa-brands[class*="twitter"] {
	background-color: black;
}

.social-bar .social-icon>a>.fa-brands[class*="pinterest"] {
	background-color: #c8232c;
	padding-left: .60rem;
}

.social-bar .social-icon>a>.fa-brands[class*="telegram"] {
	background-color: #0088cc;
}

.instead-of-label {
    display: block;
    margin: 0;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.8;
}